body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: #121212; /* Dark background */
  color: #e0e0e0; /* Light text color */
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
